import React, { FC, useEffect, useState, useCallback } from "react";
import { AppLoader, AppLoaderProps } from "../components/AppLoader/AppLoader";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/ssr";
import { getSlugFromPath } from "../util/get-slug-from-path";
import { usePortalProvider } from "../providers/portal-provider";
import {
  NotFoundError,
  RequestStatus,
  UnauthorizedError,
} from "../util/Service";

interface AppLoaderContainerProps {
  slug?: string;
}

export const AppLoaderContainer: FC<AppLoaderContainerProps> = ({ slug }) => {
  const { pathname } = useLocation();
  const { profile } = usePortalProvider();

  const [loading, setLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const { initialized, keycloak } = useKeycloak();
  const { backend, setCurrentApp, currentApp, ssr } = usePortalProvider();

  const loadApp = useCallback(
    async (pathname: string, appBasePath: string): Promise<void> => {
      const appSlug = slug || getSlugFromPath(pathname, appBasePath);

      if (currentApp && currentApp.slug === appSlug) {
        return;
      }

      const realms: any = profile?.realm_access;

      try {
        setLoading(true);
        setCurrentApp(undefined);
        setRequestStatus(undefined);
        backend?.setAccessToken(keycloak?.token);
        const app = await backend?.request(`/v1/app/${appSlug}`);
        setCurrentApp(app);
      } catch (error: unknown) {
        if (realms?.roles?.indexOf("mtcidadao-valid-user") === -1) {
          setRequestStatus(RequestStatus.NOT_VALIDATED);
        } else if (error instanceof UnauthorizedError) {
          setRequestStatus(RequestStatus.UNAUTHORIZED);
        } else if (error instanceof NotFoundError) {
          setRequestStatus(RequestStatus.NOT_FOUND);
        } else {
          setRequestStatus(RequestStatus.INTERNAL_ERROR);
        }
      } finally {
        setLoading(false);
      }
      return;
    },
    [
      backend,
      setCurrentApp,
      setLoading,
      currentApp,
      keycloak?.token,
      slug,
      profile,
    ]
  );

  useEffect(() => {
    if (!initialized && !currentApp) {
      setLoading(true);
      return;
    }
    if (!initialized) {
      return;
    }

    loadApp(pathname, APP_BASE_PATH);
  }, [pathname, keycloak?.authenticated, initialized, loadApp, currentApp]);

  const props: AppLoaderProps = {
    app: currentApp,
    loading,
    authenticated: !!keycloak?.authenticated,
    ssr,
    requestStatus,
  };

  return (
    <>
      <div
        id="root"
        dangerouslySetInnerHTML={{
          __html: currentApp?.parsedContent?.renderedContent || "",
        }}
      />
      <AppLoader {...props} />
    </>
  );
};
