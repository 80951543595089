import React from "react";

import { NotificationsContext } from "@/context/NotificationsContext";
import { usePortalAuth } from "@/providers/keycloak-provider";
import { usePortalProvider } from "@/providers/portal-provider";
import { NotificationContextType } from "../../../../types/notifications";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { HeaderElementsAuthorizedMobile } from "./Mobile";
import { HeaderElementsAuthorizedDesktop } from "./Desktop";
import { deleteCookie } from "@/util/cookies";

export const HeaderElementsAuthorized = () => {
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const { logout } = usePortalAuth();
  const { ssr, profile } = usePortalProvider();
  const history = useHistory();

  const { getUnreadNotifications } = useContext(
    NotificationsContext
  ) as NotificationContextType;

  const onLogout = useCallback(() => {
    deleteCookie("kcIdToken");
    deleteCookie("kcToken");
    deleteCookie("kcUserInfo");

    if (ssr) {
      logout();
    } else {
      keycloak?.logout();
    }
  }, [logout, keycloak, ssr]);

  const name = useMemo(() => {
    if (!profile?.name) {
      return;
    }
    const parts = (profile.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return profile.name;
  }, [profile?.name]);

  const onAccountManagementRequest = useCallback(() => {
    history.push("/app/dashboard");
  }, [history]);

  const countUnreadNotifications = useMemo(
    () => getUnreadNotifications(),
    [getUnreadNotifications]
  );

  if (!profile) {
    return null;
  }

  return (
    <React.Fragment>
      <HeaderElementsAuthorizedMobile
        email={profile.email as string}
        name={name}
        onAccountManagementRequest={onAccountManagementRequest}
        onLogout={onLogout}
        notifications={countUnreadNotifications}
      />

      <HeaderElementsAuthorizedDesktop
        profile={profile}
        name={name}
        onAccountManagementRequest={onAccountManagementRequest}
        onLogout={onLogout}
        notifications={countUnreadNotifications}
      />
    </React.Fragment>
  );
};
